import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { isEmpty } from "lodash"
import { getOgImage } from "../../utils/functions"
import Opinion from "../../components/opinion/Opinion"
import { WPViewContent } from "../../utils/WPpixel"

const OpinionPage = props => {
  const {
    pageContext: { title, seo, uri, content, acfOpinion },
  } = props
 
  useState(()=>{
    WPViewContent('View');
  },[]) 

  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2)

  return (
    <Layout lang={lang} page="opinion" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={title}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"opinion"}
            openGraphImage={getOgImage(seo)}
          />
          <div className="blank page">
            <section className="description">
              <div className="container container--small">
                <div className="page__hero">
                  <h1 className="heading heading--xl">{title}</h1>
                </div>
                {content && (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                )}
              </div>
            </section>
            <div className="container">
              <Opinion opinions={acfOpinion} />
            </div>
          </div>
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}

export default OpinionPage
