import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import Img from "gatsby-image"
import Stars1 from "../../images/stars1.svg"
import Stars2 from "../../images/stars2.svg"
import Stars3 from "../../images/stars3.svg"
import Stars4 from "../../images/stars4.svg"
import Stars5 from "../../images/stars5.svg"
import Quote from "../../images/quote.svg"
import "./style.scss";
import { isBrowser } from '../../utils/functions';


const playVideo = (e) => {
  if (isBrowser()) {
    const elementImg = e.target;
    const elementImgWrapper = elementImg.closest('.opinion__column__img');
    const elementWrapperVideo = elementImg.closest('.opinion__column').querySelector('.opinion__column__video');
    const video = elementWrapperVideo.querySelector('iframe');
    const videoUrl = video.dataset.src;
    video.src = videoUrl;
    elementImgWrapper.style.zIndex = "-1";
  }

}

const starIcon = (icon) => {
  switch (icon) {
    case '5':
      return <Stars5 />

    case '4':
      return <Stars4 />

    case '3':
      return <Stars3 />

    case '2':
      return <Stars2 />

    case '1':
      return <Stars1 />

    default:
      return <Stars5 />

  }
}


const Opinion = ({ opinions }) => {

  return (
    <section className="opinions">
      <div className="opinions__wrapper">

        {opinions.opinion.map(item => (
          <div key={uuid()} className="opinion__item">
            <div className="container">
              <div className="opinion__item__wrapper">
                {item.type === 'video' ? (
                  <div className="opinion__column opinion__column--video">
                    <div className="opinion__column__img" onClick={(e) => { playVideo(e) }}>
                      <Img fluid={item.poster.imageFile.childImageSharp.fluid} />
                    </div>
                    <div className="opinion__column__video">
                      <iframe load="lazy" width="680" height="810" data-src={`https://www.youtube.com/embed/${item.video}?autoplay=1&amp;rel=0&amp;controls=1`} frameBorder="0" allowFullScreen></iframe>
                    </div>
                  </div>
                ) : (
                  <div className="opinion__column opinion__column--img">
                    <div className="opinion__column__img noVideo">
                      <Img fluid={item.image.imageFile.childImageSharp.fluid} />
                    </div>
                  </div>
                )}
                <div className="opinion__column opinion__column--description">
                  {item.heading &&
                    <p className="opinion__column__heading heading--dash heading--big" dangerouslySetInnerHTML={{ __html: item.heading }}></p>
                  }
                  <div className="opinion__column__starts">
                    {starIcon(item.stars)}
                    {item.starsDescription}
                  </div>
                  <div className="opinion__column__quote">
                    <Quote />
                    <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                  </div>
                  <p className="opinion__column__quote__name">
                    {item.name}
                  </p>
                  <p className="opinion__column__quote__position">
                    {item.who}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}

      </div>
    </section>
  )
}
export default Opinion
